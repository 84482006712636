import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  TextField,
  Alert,
  List,
  ListItem,
  ListItemText,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from "@mui/material";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { Inbox, Send } from "@mui/icons-material";
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_BACKEND_ADDRESS;

export default function App() {
  const [currentScreen, setCurrentScreen] = useState("send"); // 'send' or 'inbox'
  const [username, setUsername] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [refId, setRefId] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [showReferralDialog, setShowReferralDialog] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const initTelegram = async () => {
      const WebApp = window.Telegram.WebApp;
      if (WebApp) {
        const userId = WebApp.initDataUnsafe.user?.id || 0;
        const queryParams = new URLSearchParams(window.location.search);
        const encodedParams = queryParams.get("tgWebAppStartParam") || 0;
        const params = encodedParams.split("__");
        console.log(params);

        let refIdParam = params[0] || null;
        let page = params[1] || null;
        let messageId = params[2] || null;

        setRefId(refIdParam);
        const chatIdFromServer = await fetchUserInfo(refIdParam || userId);
        setChatId(userId);

        await fetchMessages(userId);
  
        if (page === "inbox") {
          setCurrentScreen("inbox");
          if (messageId) {
            openMessageById(messageId);
          }
        }
      } else {
        alert("Telegram Web App is not available");
      }
    };
    initTelegram();
  }, []);
  

  const fetchUserInfo = async (ref_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/user_info?user_id=${ref_id}`);
      setUsername(response.data.full_name);
      setAvatarUrl(response.data.avatar_url);
      return response.data.chat_id;
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  const handleAcceptReferral = async () => {
    try {

      console.log(`Referral url is: ${referralLink}`)
      const prepareResponse = await axios.post(`${apiBaseUrl}/prepare_message`, { user_id: chatId });

      console.log(prepareResponse.data)
      const { id: msg_id } = prepareResponse.data.prepared_message_id;
      
      let WebApp = window.Telegram.WebApp;
      
      WebApp.shareMessage(msg_id, (success) => {
        if (success) {
          console.log("Message shared successfully!");
        } else {
          console.error("Message sharing failed.");
        }
      });
    } catch (error) {
      console.error("Error preparing or sharing the message:", error);
    }
  };
  
  const handleDeclineReferral = () => {
    setShowReferralDialog(false);
  };

  const fetchMessages = async (chat_id) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/messages?chat_id=${chat_id}`);
      setMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  const openMessageById = (messageId) => {
    const message = messages.find((msg) => msg.id === parseInt(messageId, 10));
    if (message) {
      setSelectedMessage(message);
      setShowModal(true);
    }
  };

  const confirmSendMessage = async () => {
    setShowConfirmDialog(false);
    try {


      await axios.post(`${apiBaseUrl}/ask`, {
        chat_id: refId,
        question: question,
      });
      setQuestion("");
      handleReferral();
      setSnackbarOpen(true);
      setShowReferralDialog(true);
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleReferral = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/send_referral`, { user_id: chatId.toString() });
      setReferralLink(response.data.referral_url);
    } catch (error) {
      console.error("Failed to generate referral link:", error);
      alert("Failed to generate referral link.", error);
    }
  };

  const handlePaginationChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  const currentMessages = messages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box>
      <Box p={2}>
        {currentScreen === "send" ? (
          <>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar src={avatarUrl} alt={username} sx={{ width: 56, height: 56, mr: 2 }} />
              <Typography variant="h6">{username || "Unknown User"}</Typography>
            </Box>
            <Alert severity="info" sx={{ mb: 2 }}>
              You are sending an anonymous message to <b>{username}</b>. Please ensure your message is respectful and does not contain harmful or offensive content.
            </Alert>
            <TextField
              label="Your Question"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              inputProps={{ maxLength: 250 }}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowConfirmDialog(true)}
              disabled={!question.trim()}
            >
              Send
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h5" mb={2}>
              Inbox
            </Typography>
            <List>
              {currentMessages.map((msg, index) => (
                <ListItem button key={index} divider onClick={() => handleMessageClick(msg)}>
                  <ListItemText primary={msg.shortText} />
                </ListItem>
              ))}
            </List>
            <Pagination
              count={Math.ceil(messages.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePaginationChange}
            />
          </>
        )}
      </Box>

      {/* Modal */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box p={3} bgcolor="background.paper" mx="auto" mt={5} maxWidth="400px">
          <Alert severity="info">{selectedMessage?.fullText}</Alert>
          <Button onClick={() => setShowModal(false)} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%" }}>
          You have sent an anonymous message to {username}.
        </MuiAlert>
      </Snackbar>

      <Dialog open={showReferralDialog} onClose={handleDeclineReferral}>
        <DialogTitle>Receive Anonymous Questions?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have sent an anonymous message to {username}. Now you can receive anonymous questions from others.
            If you agree, we will generate a referral link for you.
          </DialogContentText>
          {referralLink && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Your referral link: <a href={referralLink}>{referralLink}</a>
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeclineReferral} color="secondary">
            Close
          </Button>
          <Button onClick={handleAcceptReferral} color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>


      {/* Confirmation Dialog */}
      <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
        <DialogTitle>Send Anonymous Message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to send this message? It will be sent anonymously.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmSendMessage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bottom Navigation */}
      <BottomNavigation
        value={currentScreen}
        onChange={(event, newValue) => setCurrentScreen(newValue)}
        showLabels
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigationAction label="Send" value="send" icon={<Send />} />
        <BottomNavigationAction label="Inbox" value="inbox" icon={<Inbox />} />
      </BottomNavigation>
    </Box>
  );
}
