import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { TelegramWebAppProvider } from "@telegram-web-app/react";
import reportWebVitals from './reportWebVitals';
//import { AppRoot } from '@telegram-apps/telegram-ui';

const root = ReactDOM.createRoot(document.getElementById('root'));

/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/


root.render(
  <TelegramWebAppProvider>
    <App />
  </TelegramWebAppProvider>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
